*{box-sizing: border-box;
scroll-behavior:smooth;
}

body{
    /* background-image: url(./assets/BGs/BG\ Plain.png);  */
    background-color: #292828;
    
    /* background-repeat: no-repeat; */
    color: white;
    background-size: cover;
    background-position: center;
    font-family: var(--font-family-montserrat);
    margin: 0px;
}
a {
    color: unset;
    text-decoration: none;
    margin: 0;
    padding: 0;
    height: fit-content;
}


p{
    margin: 0px;
}
h2{
    margin: 0px;
}
h1{
    margin: 0px;
}
input{
    align-self: center;
}



