.ace-info {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);


}
.ace-info p{
  white-space: pre-line;
  text-align: left;
  width: 100%;
}
.ace-countdown{
  display: flex;
  gap: 10px;
  border-radius: 15px;
  font-size: 30px;
  font-weight: 700;
}

.ace-count-heading{
  font-size: 30px;
  font-weight: 700;
}
.ace-main-count-heading{
  letter-spacing: 4px;
  font-size: 30px ;
  font-weight: 600;
  font-family: var(--font-family-space);
  text-align: center;
}
.ace-info p {
    font-family: var(--font-family-space);
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: var(--color-white)



}


.ace-bottom {
    padding: 1rem;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}


.ace-row {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
    z-index: -9;
}



.ace-cards-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    
    
  }
  .ace-heading-text{
    font-family: var(--font-family-space);
    font-weight: 600;
    text-align: center;
  }
  .ace-horizontal-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(15px);
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    
    border-radius: 15px;
  }
  
  .ace-horizontal-card-left{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  }
  .ace-horizontal-card-right{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 2rem; */
    font-size: 22px;
  }
  .ace-horizontal-card-userimage{
    width: 100%;
    max-width: 300px;
    /* height: 300px; */
    /* border-radius: 50%; */
  }
  .ace-info {
    position: relative;
    align-self: center;
    
}

.ace-ellipse-img {
  position: relative;
  width: 35%;
  
  border-radius: 100%;
}

.ace-ellipse {
  padding: 0rem 1rem;
  
  display: flex;
  justify-content: space-around;
}

.director0,
.ace-name-text0,
.ace-ace {
  
  position: relative;
  justify-content: center;
  line-height: 90%;
  text-transform: capitalize;
}


.ace-ace {
  font-size: 20px;
  font-family: var(--font-source-sans-pro);
  font-weight: 500;
  color: var(--color-dimgray-200);
}
.ace-main{
  padding: 1rem;
  display: flex;
  gap: 1rem;
  min-height: calc(100vh - 75px);
  justify-content: space-around;
  flex-direction: column;
}

.ace-event-button {
  border-radius: 5px;
  background-image: var(--bg-image);
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
  width: 14vw;
  height: 50px;
  cursor: pointer;
  color: var(--color-white);
  border: 0px;
  font-size: medium;
  font-weight: 600;
  font-family: var(--font-family-space);
  border: 0px;
  text-align: center;
  
  margin-top: 2em;
}

.ace-event-button:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.ace-event-button:active,
.ace-event-button:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}




.ace-App {
    padding: 2rem 0rem;
}



@media screen and (min-width:769px) and (max-width:1000px) {

  .ace-horizontal-card {
    /* width: 100%;  */
    width: 70%;
    margin: auto;
    height: auto; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .ace-event-button {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    width: 200px;
    height: 50px;
    cursor: pointer;
    color: var(--color-white);
    border: 0px;
    font-size: medium;
    font-weight: 600;
    font-family: var(--font-family-space);
    border: 0px;
    text-align: center;
    margin-top: 2em;
}
.ace-horizontal-card-left,
    .ace-horizontal-card-right{
      font-size: 25px;
    }
  
}


@media screen and (min-width:450px) and (max-width: 768px) {
    .ace-cards-row {
      flex-direction: column; 
    }
  
    .ace-horizontal-card {
      /* width: 100%;  */
      width: 70%;
      margin: auto;
      height: auto; 
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }
  
    .ace-horizontal-card-left,
    .ace-horizontal-card-right {
      width: 100%; 
      text-align: center;
      font-size: 20px;
    }
  
    .ace-horizontal-card-userimage {
      width: 200px;
      height: 200px; 
      margin: auto;
    }
  
    .ace-event-button {
      width: 100%;
    }
  
    .ace-info p {
      font-size: 16px; 
    }
    .ace-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 200px;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: medium;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
      margin-top: 2em;
  }
  
  }

  @media  screen and (min-width:400px) and (max-width:450px) {
    .ace-horizontal-card {
      /* width: 100%;  */
      width: 70%;
      margin: auto;
      height: auto; 
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }
    .ace-horizontal-card-userimage {
      width: 140px;
      height: 140px; 
      margin: auto;
    }
    .ace-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 8em;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: medium;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
      margin-top: 2em;
  }
  }



@media (max-width: 400px) {
.ace-cards-row{
    scale: 0.9;
}
.ace-horizontal-card-userimage {
    width: 150px;
    height: 150px;
  }
  .ace-event-button {
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    width: 10em;
    height: 50px;
    cursor: pointer;
    color: var(--color-white);
    border: 0px;
    font-size: medium;
    font-weight: 600;
    font-family: var(--font-family-space);
    border: 0px;
    text-align: center;
    margin: auto;
}
.ace-horizontal-card-right{
  /* width: 100%; */
  text-align: center;
}
.ace-horizontal-card {
  /* width: 100%;  */
  width: 50%;
  margin: auto;
  height: auto; 
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
}
@media (max-width: 350px) {
    .ace-cards-row{
        scale: 0.8;
    }
    .ace-event-button {
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
      width: 10em;
      height: 50px;
      cursor: pointer;
      color: var(--color-white);
      border: 0px;
      font-size: small;
      font-weight: 600;
      font-family: var(--font-family-space);
      border: 0px;
      text-align: center;
      margin: auto;
  }
  .ace-horizontal-card-right{
    /* width: 100%; */
    text-align: center;
  }
  .ace-horizontal-card {
    /* width: 100%;  */
    width: 90vw;
    margin: auto;
    height: 22em; 
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .ace-horizontal-card-userimage {
    width: 12em;
    height: 13em;
  }
    }
  