

.signup-form__google{
  border-radius: 10px;
  overflow: hidden;
}
.signup-success-main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: calc(100vh - 75px);
  
  }
  
  
h2{
  font-family: var(--font-family-akira);
}
  
.valid{
  color: var(--color-white);
}
.invalid{
  color: var(--color-white);
  padding-left: 0.5rem;
  
}
.instructions,.errmsg{
  display: flex;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-family-space);

}
.signup-form-input-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.offscreen,.hide{
  display: none;
}
.signup-main{
  display: flex;
  padding-bottom: 3rem;
  padding-top: 1rem;
  
  justify-content: space-around;
  
}

.signup-main-core {
position: relative;


width: 1000px;
min-width: 1000px;
min-height: 600px;
height: 600px;
padding: 25px;
background-color: transparent;
backdrop-filter: blur(10px);
box-shadow:0px 0px 20px 0px var(--fg-color);
border-radius: 12px;
overflow: hidden;
}

.signup-container {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
width: 600px;
height: 100%;
padding: 25px;
background-color: transparent;
backdrop-filter: blur(10px);
transition: 1.25s;
}

.signup-form {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
gap: 1rem;
height: 100%;

}
.signup-form__icon {
object-fit: contain;
width: 30px;
margin: 0 5px;
opacity: 1;
transition: 0.15s;

}
.signup-form__icon:hover {
opacity: 0.5;
transition: 0.15s;
cursor: pointer;
}
.signup-form__input {
width: 350px;
height: 40px;
margin: 4px 0;
padding-left: 25px;
font-size: 13px;
letter-spacing: 0.15px;
border: none;
outline: none;
font-family: var(--font-family-space);
background-color: transparent;
backdrop-filter: blur(10px);
transition: 0.25s ease;
color: var(--color-white);
border-radius: 8px;
box-shadow:0px 0px 5px 0px var(--fg-color);
}
.signup-form__input:focus {
box-shadow: inset 4px 4px 4px var(--fg-color), inset -4px -4px 4px var(--fg-color);
}
.signup-form__span {
  text-decoration: underline;
  font-size: 18px;
  font-family: var(--font-family-space);
  color: var(--color-white);
}
.signup-form__span1 {
  text-decoration: underline;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: var(--font-family-akira);
  color: var(--color-white);
  }
.signup-form__link {
color: var(--color-white);
font-size: 15px;
font-family: var(--font-family-akira);
margin-top: 25px;
border-bottom: 1px solid var(--fg-color);
line-height: 2;
cursor: pointer;
}
.signup-form__login{
  text-decoration: underline;
  color: var(--color-white);
  font-size: 15px;
  font-family: var(--font-family-akira);
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 2;
  cursor: pointer;
}
.signup-form__link:hover {
  text-shadow: 0px 0px 15px var(--fg-color);
}


.signup-title {
  font-size: 34px;
  font-weight: 700;
  font-family: var(--font-family-akira);
  line-height: 2;
  color: var(--color-white);
}
.signup-switch__title{
  font-size: 25px;
  text-align: center;
}
.signup-description {
  font-size: 18px;
  font-family: var(--font-family-space);
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}




.signup-a-container {
z-index: 100;
left: calc(100% - 600px );
}

.signup-b-container {
left: calc(100% - 600px );
z-index: 0;
}
.signup-switch__title{
  width: 100%;
}
.signup-switch {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 400px;
/* padding: 2rem; */
transition: 1.25s;
background-color: transparent;
backdrop-filter: blur(10px);
overflow: hidden;
box-shadow:0px 0px 20px 0px var(--fg-color);
}

.signup-switch__container {
display: flex;
gap: 1rem;
justify-content: center;
align-items: center;
flex-direction: column;
position: absolute;
width: 400px;
padding: 2rem;
transition: 1.25s;
}
.signup-switch__button {
cursor: pointer;
box-shadow:0px 0px 20px 0px var(--fg-color);
}
.signup-switch__button:hover {
box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
transform: scale(0.985);
transition: 0.25s;
}
.signup-switch__button:active, .switch__button:focus {
box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
transform: scale(0.97);
transition: 0.25s;
}
.signup-switch__description{
  color: var(--color-white);
}
.signup-button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--font-family-akira);
  letter-spacing: 1.15px;
  background-color: transparent;
  /* background-image: var(--bg-image); */
  background-color:var(--fg-color);
  backdrop-filter: blur(10px);
  color: var(--color-white);
  box-shadow:0px 0px 20px 0px var(--fg-color);
  border: none;
  outline: none;
}
.signup-success-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100% !important;
    padding: 50px;
    z-index: 200;
    transition: 1.25s;
    background-color: transparent;
    backdrop-filter: blur(10px);
    
    overflow: hidden;
    
  }
  
  
  
  
  
  .signup-success-switch__button {
    cursor: pointer;
    box-shadow:0px 0px 20px 0px var(--fg-color);
  }
  
  .signup-success-switch__button:hover {
    box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
    transform: scale(0.985);
    transition: 0.25s;
  }
  



.signup-success-main-core {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 600px;
  padding: 25px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  box-shadow:0px 0px 20px 0px var(--fg-color);
  border-radius: 12px;
  overflow: hidden;
}



.signup-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 600px;
  height: 100%;
  padding: 25px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  transition: 1.25s;
}

.signup-success-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.signup-success-form__icon {
  object-fit: contain;
  width: 30px;
  margin: 0 5px;
  opacity: 1;
  transition: 0.15s;

}

.signup-success-form__icon:hover {
  opacity: 0.5;
  transition: 0.15s;
  cursor: pointer;
}

.signup-success-form__input {
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: var(--font-family-akira);
  background-color: transparent;
  backdrop-filter: blur(10px);
  transition: 0.25s ease;
  color: var(--color-white);
  border-radius: 8px;
  box-shadow:0px 0px 20px 0px var(--fg-color);
}

.signup-success-form__input:focus {
  box-shadow: inset 4px 4px 4px var(--fg-color), inset -4px -4px 4px var(--fg-color);
}

.signup-success-form__span {
  margin-top: 30px;
  margin-bottom: 12px;
  font-family: var(--font-family-akira);
  color: var(--color-white);
}

.signup-success-form__link {
  color: var(--color-white);
  font-size: 15px;
  font-family: var(--font-family-akira);
  margin-top: 25px;
  border-bottom: 1px solid var(--fg-color);
  line-height: 2;
  cursor: pointer;
}
.signup-success-form__login{
  color: var(--color-white);
  font-size: 15px;
  font-family: var(--font-family-akira);
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 2;
  cursor: pointer;
}

.signup-success-form__link:hover {


  text-shadow: 0px 0px 15px var(--fg-color);

}



.signup-success-title {
  font-size: 34px;
  font-weight: 700;
  align-items: center;
  text-align: center;
  font-family: var(--font-family-akira);
  color: var(--color-white);
}

.signup-success-description {
  font-size: 14px;
  font-family: var(--font-family-akira);
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}




.signup-success-a-container {
  z-index: 100;
  left: calc(100% - 600px);
}

.signup-success-b-container {
  left: calc(100% - 600px);
  z-index: 0;
}

.signup-success-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1000px;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color: transparent;
  backdrop-filter: blur(10px);
  
  overflow: hidden;
  
}



.signup-success-switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  transition: 1.25s;
  padding: 0rem 5rem;
  flex-wrap: wrap;
}

.signup-success-switch__button {
  cursor: pointer;
  box-shadow:0px 0px 20px 0px var(--fg-color);
}

.signup-success-switch__button:hover {
  box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
  transform: scale(0.985);
  transition: 0.25s;
}

.signup-success-switch__button:active,
.switch__button:focus {
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
  transform: scale(0.97);
  transition: 0.25s;
}

.signup-success-switch__description {
  color: var(--color-white);
}

.signup-success-button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 14px;
  font-family: var(--font-family-akira);
  letter-spacing: 1.15px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  color: var(--color-white);
  box-shadow:0px 0px 20px 0px var(--fg-color);
  border: none;
  outline: none;
}

.signup-success-button:hover {
  box-shadow: 6px 6px 10px var(--fg-color), -6px -6px 10px var(--fg-color);
  transform: scale(0.985);
  transition: 0.25s;
}

.signup-success-button:active,
.signup-success-button:focus {
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
  transform: scale(0.97);
  transition: 0.25s;
}




  
  .signup-success-switch__button:active,
  .switch__button:focus {
    box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
    transform: scale(0.97);
    transition: 0.25s;
  }
  
  .signup-success-switch__description {
    color: var(--color-white);
  }
.signup-button:hover {
  box-shadow: 6px 6px 10px var(--fg-color), -6px -6px 10px var(--fg-color);
  transform: scale(0.985);
  transition: 0.25s;
}
.signup-button:disabled {
  box-shadow:0px 0px 20px 0px var(--fg-color);

transform: scale(0.985);
transition: 0.25s;
}
.signup-button:active, .signup-button:focus {
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
  transform: scale(0.97);
  transition: 0.25s;
}




.is-txr {
left: calc(100% - 400px );
transition: 1.25s;
transform-origin: left;
}

.is-txl {
left: 0;
transition: 1.25s;
transform-origin: right;
}

.is-z200 {
z-index: 200;
transition: 1.25s;
}

.is-hidden {
visibility: hidden;
opacity: 0;
position: absolute;
transition: 1.25s;
}

.is-gx {
animation: is-gx 1.25s;
}

@keyframes is-gx {
0%, 10%, 100% {
  width: 400px;
}
30%, 50% {
  width: 500px;
}
}