.menu-main{
    display: flex;
    justify-content: center;
    text-align: center;
    /* background-color: var(--color-footer); */
    border-radius: 25px;
    font-family: var(--font-family-akira);
    flex-wrap: wrap;
}
.menu-core{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family-akira);
    font-weight: 600;

}
