 .devs-main{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  
  
}
.devs-core{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content:center;
  gap: 30px;
  
  
}
.devs-title-name-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.devs-founder-card-info-icons-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.devs-founder-card-bottom{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.devs-cards-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}
.devs-heading-text{
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  margin: 0px;
  text-align: center;
}
.devs-horizontal-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 800px;
  height: 250px;
  background-color: var(--member-plaque);
  border-radius: 15px;
}

.devs-horizontal-card-left{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
}
.devs-horizontal-card-right{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}
.devs-horizontal-card-userimage{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.devs-card-front,
.devs-card-back{
  backface-visibility: hidden;
}
.devs-card-back{
  transform: rotateY(180deg);
}
.devs-cards-card{
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out 0s;
}

.devs-cards-card:hover {
  transform: rotateY(180deg);
}

.devs-social-icons{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 25px;
}

.devs-vertical-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 450px;
  background-color: var(--member-plaque);
  border-radius: 15px;
  padding: 1rem;
}
.devs-vertical-card-userimage{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.devs-verical-card-top{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.devs-vertical-card-bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.devs-vertical-name-text{
  font-size: xx-large;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  text-align: center;
}
.devs-vertical-info-text{
  font-size: medium;
}

@media (max-width: 768px) {
  .devs-horizontal-card {
    flex-direction: column;
    width: auto;
    height: auto;
    text-align: center;
  }

  .devs-horizontal-card-left,
  .devs-horizontal-card-right {
    padding: 1rem;
  }

  .devs-horizontal-card-userimage {
    width: 150px;
    height: 150px;
  }

  .devs-vertical-card {
    width: auto;
    height: auto;
  }

  .devs-vertical-card-userimage {
    width: 150px;
    height: 150px;
  }

  .devs-verical-card-top {
    margin-bottom: 1rem;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<KARAN CODE>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.devs-main-cards {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  row-gap: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 80px;
}

.devs-main-head {
  text-align: center;
  letter-spacing: 6px;

}
.devs-card-container {
  perspective: 1000px;
  
}
.devs-back-card-title{
  margin: 0;

}
.devs-card-bottom-h2{
  margin: 0px;
  font-size: 18px;
}
.devs-card {
  width: 250px;
  height: 22em;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  /* margin: auto;s */
  border-radius: 2px;
  /* margin-bottom: 80px; */
}

.devs-card:hover {
  transform: rotateY(180deg);
}

.devs-front,
.devs-back {
  position: absolute;
  width: 100%;
  height: 95%;
  backface-visibility: hidden;
  /* border: 4px solid red; */
  box-shadow: 0px 0px 5px 1.5px rgb(238, 233, 233);
}

.devs-front {
  background-image: var(--bg-image);
  box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
  color: white;
  display: flex;
  flex-direction: column;
  height: 95%;
  border-radius: 30px;
}

.devs-back-description-founder {
  font-size: 16px;
  text-align: left;
  font-family: var(--font-family-montserrat);
  /* padding: 0; */
  margin: 0px;
}
.devs-front-founder {
  background-image: var(--bg-image);
  box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
  color: white;
  display: flex;
  width: 95%;
  height: 250px;
  border-radius: 30px;
}
.devs-bgimg-founder {
  width: 350px;
  height: 100%;
  padding-top: 0px;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  
}
.devs-back-title-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.devs-back-bottom-container{
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.devs-back-socials-container,
.devs-back-skills-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
}
.devs-back {
  box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  transform: rotateY(180deg);
  color: white;
  padding: 10px;
  border-radius: 30px;

}

.devs-bgimg {
  width: 100%;
  height: 70%;
  padding-top: 0px;
  object-fit: cover;
  /* padding: 6px; */
  /* border:  solid white; */
  /* for image radius */
  /* border-radius: 4px solid red; */
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  
}
.devs-card-text{
  padding: 10px 0px;
}
.devs-heading,
.devs-name {
  text-align: left;
  font-family: var(--font-family-montserrat);
  padding-left: 20px;
  margin: 0px;


}
.devs-title{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.devs-title-founder{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.devs-card-founder-right{
  display: flex;
  padding: 10px 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.devs-name-founder {
  text-align: left;
  margin: 0px;
  font-family: var(--font-family-montserrat);
  font-weight: 600;

}

.devs-heading {
  font-size: 30px;
  padding-top: 10px;
}

.devs-role {
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  margin: 0px;
  font-family: var(--font-family-montserrat);
}
.devs-role-founder {
  font-size: 24px;
  text-align: left;
  margin: 0px;
  font-family: var(--font-family-montserrat);
}
.devs-signature-container{
  display: flex;
  justify-content: flex-end;
  border-radius: 30px;
  padding-right: 1rem;  
}
.devs-signature-container-founder{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-radius: 30px;
  width: 100%;
}
.devs-signature-founder {
  width: 130px;
  height: 80px;
  border-radius: 15px;

}
.devs-signature {
  width: 50px;
  border-radius: 15px;

}



.devs-social-media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.devs-skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.devs-logos {
  height: 1.5em;
  width: 2em;


}
.devs-logos:hover {
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7)) brightness(150%); /* Adjust the glow effect */

}

.devs-back-description {
  font-size: 12px;
  text-align: left;
  font-family: var(--font-family-montserrat);
  margin: 0px;
  /* padding: 0; */
}



@media (max-width:1220px){
  .devs-title-founder{
    flex-wrap: wrap;
    gap: 10px;
    
  }
  .devs-front-founder{
    height: 350px;
  }
}


@media (max-width:1060px){
  .devs-name-founder{
    font-size: 28px;
  }
  .devs-front-founder{
    height: 450px;
  }
  .devs-founder-card-bottom{
    flex-wrap:wrap;
    justify-content: flex-end;
    gap:10px
  }
}
@media (max-width:960px){
  .devs-front-founder{
    height: 450px;
  }
  .devs-bgimg-founder{
    width: 300px;
  }
}
@media (max-width:860px){
  .devs-name-founder{
    font-size: 18px;
  }
  .devs-title-founder{
    flex-direction: column;
    gap: 20px;
  }
  .devs-signature-founder{
    height: 60px;
    width: 60px;
  }
  .devs-role-founder{
    font-size: 18px;
  }
  .devs-card-founder-right{
    gap: 20px;
  }
  .devs-front-founder{
    height: 500px;
    width: 250px;
    flex-direction: column;
  }
  .devs-founder-card-bottom{
    justify-content: flex-end;
    gap: 0px;

  }
  .devs-back-description-founder{
    display: none;
  }
  .devs-bgimg-founder{
    width: 250px;
    height: 250px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
  }
  
}