.contact-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;

}

.contact-heading{
    text-align: center;
    font-family: var(--font-family-montserrat);
}
.contact-cgc-web-btn{
     
        display: flex;
        width: 20vw;
        /* border-radius: 20px; */
        justify-content: center;
        align-items: center;
        height: 5vh;
        opacity: 1;
        border: 2px solid black;
        color: rgb(253, 253, 253);
        margin-top: 30px;
        padding-left: 20px;
        margin-bottom: 4em;
        text-align: start;
        font-weight: bolder;
        float: right;
        font-size: large;
        transform: scale(40px 40px);
        background-image: var(--bg-image);
        backdrop-filter: blur(15px);
        color: var(--color-white);
        box-shadow: 0.1em 0.1em 0.4em 0.3em var(--color-iobutton);
        font-family: inherit;
        transition: transform 0.2s;
        font-family: var(--font-family-montserrat);
    }
    .contact-cgc-web-btn:hover {
        transition: all;
        transition-duration: 0.5s;
        color: aliceblue;
        transform: scale(0.96);
        box-shadow: 0.1em 0.1em 0.3em 0.4em var(--color-iobutton);
    }
    
.contact-us-icons-cgc{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 2em;
       
     }

.contact-map{
    width: 100%;
    height: 400px;
    border-radius: 15px;
}
.contact-gps-text{
    width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-end;
}
.contact-gps{
    display: flex;
    padding: 1rem;
    width: 95%;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
    backdrop-filter: blur(15px);
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}
.contact-gps-heading {
    text-align: right;
    font-family: var(--font-family-montserrat);
}
.contact-gps-address{
    text-align: right;
    font-family: var(--font-family-montserrat);
}

.contact-us-social-media-icons{
    width: 3em;
    height: 2em;
}

@media(max-width:1400px){
    .contact-gps{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
    .contact-gps-heading{
        text-align: center;
        font-family: var(--font-family-montserrat);
    }
    .contact-gps-address{
        text-align: center;
        font-family: var(--font-family-montserrat);
    }
   
}

@media(max-width:900px){
    
    .contact-gps-heading{
        font-size: larger;
        text-align: center;
        font-family: var(--font-family-montserrat);
    
    }
    .contact-gps-text{
        justify-content: center;
    align-items: center;
    text-align: center;
    }
    .contact-us-social-media-icons{
        width: 2em;
        height: 2em;
    }
    
}